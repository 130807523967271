.selected {
  display: none;
}

.selected-box .selected {
  display: flex;
}

.selected-box .unselected {
  display: none;
}
